@use "global" as *;

// ============================================================
//  project/_index-information.scss
// ============================================================

.p-index-information{
	margin: 0 0 vw(100);
	@include sp{
		margin: 0 0 svw(50);
	}//sp
	
	&__inner{
		width: vw(1000);
		padding: 0 vw(100);
		margin: 0 auto;
		box-sizing: border-box;
		@include sp{
			width: auto;
			padding: 0 svw(40);
		}//sp
		
		ul{
			text-align: left;
			margin: 0 0 vw(20);
			@include sp{
				margin: 0;
				border-bottom: svw(1) dotted #ccc;
			}//sp
			
			li{
				padding: vw(20) 0;
				border-bottom: vw(1) dotted #ccc;
				display: flex;
				flex-wrap: wrap;
				column-gap: vw(30);
				@include sp{
					padding: 0;
					margin: 0 0 svw(25);
					border: none;
					display: block;
				}//sp
				
				&:last-child{
					border-bottom: none;
				}//last-child
				
				&:nth-child(4),
				&:nth-child(5){
					display: none;
				}//
				
				.date{
					color: #535353;
					font-size: vw(14);
					line-height: 1.2;
					@include sp{
						font-size: svw(14);
						line-height: 1.4;
						display: block;
					}//sp
				}//.date
				
				a{
					color: #535353;
					font-size: vw(16);
					line-height: 1.2;
					max-width: vw(670);
					@include sp{
						font-size: svw(18);
						line-height: 1.4;
						max-width: 100%;
					}//sp
					&:hover{
						opacity: .6;
					}//:hover
				}//a
			}//li
		}//ul
		
		.viewmore{

			text-align: right;
			@include sp{
				margin: svw(20) 0 0;
			}//sp
			
			a{
				font-size: vw(16);
				line-height: 1;
				@include sp{
					font-size: svw(18);
				}//sp
			}//a
		}//.viewmore
	}//__inner
}//.p-index-main-visual