@use "global" as *;

// ============================================================
//  project/_index-message.scss
// ============================================================

.p-index-message{
	margin: 0 0 vw(100);
	@include sp{
		margin: 0 0 svw(50);
	}//sp
	
	&__inner{
		width: vw(1000);
		padding: vw(50) vw(40);
		margin: 0 auto;
		box-sizing: border-box;
		background: #f1eeee;
		@include sp{
			width: auto;
			padding: svw(25) svw(0);
		}//sp

		h3{
			margin: 0 0 vw(30);
			line-height: 1.4;
			font-size: vw(30);
			font-weight:700;
			text-align: center;
			color: #000;
			font-family: "Noto Sans JP",Sans-Serif;
			@include sp{
				font-size: svw(26);
			}//sp

		}
		
		.panel{
			margin: 0 0 vw(50);
			background: #fff;
			padding: vw(50) vw(60) vw(120);
			border-radius: 0 0 vw(210) vw(210);
			@include sp{
				padding: svw(25) svw(30) svw(60);
				border-radius: 0 0 svw(100) svw(100);
			}//sp
			
			figure{
				margin: 0 auto vw(30);
				max-width: 240px;
				text-align: center;
			}
			
			dl{
				margin: 0 0 vw(30);
				
				dt{
					margin: 0 0 vw(20);
					line-height: 1.4;
					font-size: vw(22);
					font-weight:400;
					text-align: center;
					font-family: "Noto Sans JP",Sans-Serif;
					color: #ff6600;
					@include sp{
						font-size: svw(18);
					}//sp
				}
				
				dd{
					font-family: "Noto Serif JP",serif;
					font-weight:800;
					color: #4d4d4d;
					font-size: vw(33);
					@include sp{
						font-size: svw(29);
					}//sp
					
					span{
						display: block;
						margin: vw(10) 0 0;
						font-size: vw(18);
						color: #4d4d4d;
						font-weight:400;
						font-family: "Noto Sans JP",Sans-Serif;
						@include sp{
							font-size: svw(14);
						}//sp
					}
				}
			}
			
			p{
				line-height: 1.8;
				font-size: vw(20);
				font-weight:400;
				text-align: left;
				font-family: "Noto Sans JP",Sans-Serif;
				color: #4d4d4d;
				@include sp{
					line-height: 1.4;
					font-size: svw(16);
				}//sp
			}
			
		}
		
		section{
			
			&:nth-of-type(2){
				margin: vw(90) 0 0;
			}
			
			@include sp{
				padding: svw(0) svw(25);
			}//sp
			
			
			h4{
				margin: 0 0 vw(20);
				padding: 0 0 vw(10);
				font-size: vw(26);
				font-weight:700;
				text-align: left;
				color: #000;
				font-family: "Noto Sans JP",Sans-Serif;
				border-bottom: 1px solid #fff;
				@include sp{
					font-size: svw(22);
				}//sp
			}
			
			p{
				margin: 0 0 vw(20);
				line-height: 1.8;
				font-size: vw(20);
				font-weight:400;
				text-align: left;
				font-family: "Noto Sans JP",Sans-Serif;
				color: #000;
				@include sp{
					line-height: 1.4;
					font-size: svw(16);
				}//sp
			}
			
			ul{
				display: flex;
				justify-content: space-between;
				gap: 0 30px;
				
				li{
					img{
						max-height: 190px;
					}
				}
				
				@include sp{
					flex-wrap: wrap;
					gap: 0 0;
					li{
						flex-basis: 49%;
						background: #fff;
						margin: 0 0 svw(5);
						text-align: center;
						padding: svw(5) svw(10);
						img{
							max-height: 190px;
						}
					}
					
				}//sp
				
				
			}
		}
		
		
	}//__inner
}//.p-index-main-visual