// ============================================================
//  global/_fonts.scss
// ============================================================

/*----------------------------------------
	Web Fonts
----------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Zen+Kaku+Gothic+New:400,500,700,900&display=swap&subset=japanese');
@import url('https://fonts.googleapis.com/css?family=Shippori+Mincho:400,500,600,700,800&display=swap&subset=japanese');
@import url('https://fonts.googleapis.com/css?family=Outfit:200,300,400,500,600,700&display=swap');

/*----------------------------------------
	Fonts
----------------------------------------*/
$fontGothic: 'Zen Kaku Gothic New', "Yu Gothic", YuGothic, 'Noto Sans JP', "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$fontMincho: 'Shippori Mincho', '游明朝', 'Yu Mincho', YuMincho, 'Hiragino Mincho Pro', serif;
$fontOutfit: 'Outfit', sans-serif;