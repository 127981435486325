@use "global" as *;

// ============================================================
//  component/_modal.scss
// ============================================================

.c-modal{
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	opacity: 0;
	pointer-events: none;
	transition: all .6s ease;
	overflow: scroll;
	
	&::-webkit-scrollbar{
		display: none;
	}//::-webkit-scrollbar
	
	&.is_active{
		opacity: 1;
		pointer-events: auto;
	}//.is_active
	
	&__overlay{
		width: 100%;
		height: 100%;
		background-color: rgba(171,171,171,.9);
		position: fixed;
		top: 0;
		left: 0;
	}//__overlay
	
	&__inner{
		width: vw(900);
		padding: vw(30);
		background-color: #fff;
		border-radius: vw(6);
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		box-sizing: border-box;
		@include sp{
			width: svw(350);
			padding: 0;
			border-radius: svw(6);
			top: svw(80);
			transform: translateX(-50%);
			display: block;
		}//sp
		
		figure{
			width: vw(400);
			@include sp{
				width: auto;
			}//sp
			
			img{
				width: 100%;
				height: auto;
				border-radius: vw(6);
				@include sp{
					height: svw(260);
					border-radius: svw(6) svw(6) 0 0;
					object-fit: cover;
				}//sp
			}//img
		}//figure
		
		.detail{
			width: vw(410);
			@include sp{
				width: auto;
				padding: svw(20);
			}//sp
			
			.heading{
				margin: 0 0 vw(30);
				@include sp{
					margin: 0 0 svw(10);
				}//sp
				
				.position{
					font-size: vw(16);
					font-weight: 500;
					letter-spacing: .05em;
					line-height: 1.3;
					margin: 0 0 vw(5);
					@include sp{
						font-size: svw(14);
						margin: 0 0 svw(3);
					}//sp
				}//.position
				
				.company{
					font-size: vw(16);
					font-weight: 500;
					letter-spacing: .05em;
					line-height: 1.3;
					margin: 0 0 vw(20);
					@include sp{
						font-size: svw(14);
						margin: 0 0 svw(5);
					}//sp
				}//.company
				
				.name{
					font-size: vw(26);
					font-weight: 500;
					letter-spacing: .1em;
					line-height: 1.3;
					@include sp{
						font-size: svw(24);
					}//sp
				}//.name
			}//.heading
			
			.meta{
				margin: 0 0 vw(30);
				@include sp{
					margin: 0 0 svw(20);
				}//sp
			
				li{
					margin: 0 0 vw(5);
					@include sp{
						margin: 0 0 svw(5);
					}//sp
					
					&:last-child{
						margin: 0;
					}//:last-child
					
					dl{
						display: flex;
						flex-wrap: wrap;
						align-items: baseline;
						justify-content: space-between;
						@include sp{
							justify-content: flex-start;
						}//sp
						
						dt{
							color: #C0C0C0;
							font-size: vw(12);
							font-weight: 500;
							letter-spacing: .05em;
							line-height: 1.3;
							@include sp{
								font-size: svw(11);
								width: svw(75);
							}//sp
						}//dt
						
						dd{
							font-size: vw(16);
							font-weight: 500;
							letter-spacing: .05em;
							line-height: 1.5;
							width: vw(320);
							@include sp{
								font-size: svw(12);
								width: auto;
							}//sp
						}//dd
					}//dl
				}//li
			}//.meta
			
			.desc{
				font-size: vw(16);
				font-weight: 500;
				letter-spacing: .05em;
				line-height: 1.6;
				@include sp{
					font-size: svw(16);
					line-height: 1.5;
				}//sp
			}//.desc
		}//.detail
		
		&.is_adjust{
			align-items: flex-start;
			
			figure{
				@include sp{
					padding: svw(20) svw(20) 0;
				}//sp
				
				img{
					@include sp{
						border-radius: svw(6);
					}//sp
				}//img
			}//figure
			
			.detail{
				padding: vw(10) 0;
				@include sp{
					padding: svw(20);
				}//sp
				
				.meta{
					li{
						margin: 0 0 vw(15);
						@include sp{
							margin: 0 0 svw(8);
						}//sp
						
						&:last-child{
							margin: 0;
						}//:last-child
						
						dl{
							display: block;
							
							dt{
								margin: 0 0 vw(5);
								@include sp{
									width: auto;
									margin: 0 0 svw(5);
								}//sp
							}//dt
							
							dd{
								width: vw(330);
								@include sp{
									font-size: svw(16);
									width: auto;
								}//sp
								
								&.name,
								&.alt{
									font-size: vw(18);
									@include sp{
										font-size: svw(16);
									}//sp
								}//
							}//dd
						}//dl
					}//li
				}//.meta
			}//.detail
		}//.is_adjust
		
		&__close{
			width: vw(44);
			position: absolute;
			top: 0;
			right: calc(0% - vw(64));
			cursor: pointer;
			@include sp{
				width: svw(44);
				top: svw(-60);
				right: 0;
			}//sp
			
			img{
				width: 100%;
				height: auto;
				transition: transform .6s ease;
			}//img
			
			&:hover{
				img{
					transform: rotate(180deg);
				}//img
			}//:hover
		}//__close
	}//__inner
}//.c-modal