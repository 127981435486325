@use "global" as *;

// ============================================================
//  project/_index-category.scss
// ============================================================

.p-index-category{
	margin: 0 0 vw(100);
	@include sp{
		margin: 0 0 svw(50);
	}//sp
	
	&__inner{
		width: vw(1000);
		padding: 0 vw(40);
		margin: 0 auto;
		box-sizing: border-box;
		@include sp{
			width: auto;
			padding: 0 0 0 svw(20);
			overflow: scroll;
		}//sp
		
		.simplebar-track{
			display: none !important;
			@include sp{
				display: block !important;
			}//sp
		}//.simplebar-track
		
		.scroll-x{
			@include sp{
				overflow: scroll;
			}//sp
			
			ul{
				display: flex;
				flex-wrap: wrap;
				column-gap: vw(20);
				row-gap: vw(20);
				@include sp{
					width: max-content;
					flex-wrap: nowrap;
					column-gap: svw(20);
					row-gap: 0;
					margin: 0 0 svw(25);
				}//sp
				
				li{
					width: calc((100% - vw(60))/4);
					@include sp{
						width: svw(125);
					}//sp
					
					a{
						display: block;
						
						figure{
							margin: 0 0 vw(20);
							@include sp{
								margin: 0 0 svw(15);
							}//sp
							
							img{
								width: 100%;
								height: auto;
								border-radius: vw(30);
								@include sp{
									border-radius: svw(15);
								}//sp
							}//img
						}//figure
						
						p{
							color: #525252;
							font-size: vw(16);
							font-weight: 600;
							letter-spacing: .03em;
							line-height: 1;
							text-align: center;
							@include sp{
								font-size: svw(14);
							}//sp
						}//p
					}//a
				}//li
			}//ul
			
			.simplebar-track{
				background-color: #f1e5c6;
				border-radius: 7px;
			}//.simplebar-track
			
			.simplebar-scrollbar{
				&::before{
					background-color: #b79027;
				}//::before
			}//.simplebar-scrollbar
		}//.scroll-x
	}//__inner
}//.p-index-main-visual