@use "global" as *;

// ============================================================
//  project/_index-information.scss
// ============================================================

.p-index-problems{
	margin: 0 0 vw(100);
	@include sp{
		margin: 0 0 svw(50);
	}//sp
	
	&__inner{
		width: vw(1000);
		padding: 0 vw(40);
		margin: 0 auto;
		box-sizing: border-box;
		@include sp{
			width: auto;
			padding: 0 svw(20);
		}//sp
		
		ul{
			display: flex;
			flex-wrap: wrap;
			column-gap: vw(10);
			@include sp{
				display: block;
			}//sp
			
			li{
				width: vw(300);
				@include sp{
					width: auto;
					margin: 0 0 svw(15);
				}//sp
				
				&:last-child{
					margin: 0;
				}//:last-child
				
				a{
					padding: vw(5) vw(5) 0;
					background-color: #b79027;
					display: block;
					position: relative;
					@include sp{
						padding: svw(5);
						background-color: transparent;
						border: svw(2) solid #b79027;
						box-sizing: border-box;
						display: flex;
						flex-wrap: wrap;
						column-gap: svw(5);
					}//sp
					
					figure{
						@include sp{
							width: svw(88);
						}//sp
						
						img{
							width: 100%;
							height: auto;
						}//img
					}//figure
					
					h4{
						color: #fff;
						font-size: vw(36);
						font-weight: 600;
						line-height: 1.4;
						padding: vw(20);
						@include sp{
							font-size: svw(20);
							width: svw(228);
							padding: 0 svw(10);
							background-color: #b79027;
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							box-sizing: border-box;
							
						}//sp
					}//h4
					
					svg{
						width: vw(12);
						height: auto;
						position: absolute;
						bottom: vw(30);
						right: vw(25);
						@include sp{
							width: svw(6);
							bottom: auto;
							top: 50%;
							right: svw(15);
							transform: translateY(-50%);
						}//sp
					}//svg
				}//a
			}//li
		}//ul
	}//__inner
}//.p-index-main-visual