@use "global" as *;

// ============================================================
//  project/_header-container.scss
// ============================================================

.p-header-container{
	background-color: #421f1b;
	@include sp{
		width: 100%;
		height: svw(60);
		background-color: #fff;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
	}//sp
	
	&__inner{
		width: vw(1000);
		margin: 0 auto;
		@include sp{
			width: vw(1000);
			margin: 0 auto;
			width: auto;
			padding: svw(12) svw(15);
		}//sp
		
		&__top{
			padding: vw(10) 0 vw(30);
			display: flex;
			justify-content: flex-start;
			@include sp{
				display: none;
			}//sp
			
			.-point-dokuji td{
				color: #fff;
			}
			
			p{
				color: #fff;
				font-size: vw(14);
				letter-spacing: .03em;
				line-height: 1;
				border-left: 1px solid #fff;
				
				&.guest{
					padding: 0 vw(20) 0 0;
					margin: 0 vw(20) 0 0;
					border-right: vw(1) solid #fff;
					
					.point{
						padding: 0 vw(3);
					}//.point
				}//.guest
				
				a{
					color: #fff;
					
					&:hover{
						text-decoration: underline;
					}//:hover
				}//a
			}//p
		}//__top
		
		&__main{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			padding: vw(10) 0 vw(5);
			
			&__heading{
				width: vw(540);
				text-align: left;
				@include sp{
					width: auto;
					margin: 0 auto;
				}//sp
				
				.logo{
					width: vw(340);
					@include sp{
						width: svw(170);
					}//sp
					
					img{
						width: 100%;
						height: auto;
					}//img
				}//.logo
				
				h1{
					color: #fff;
					font-size: vw(14);
					letter-spacing: .03em;
					line-height: 1.4;
					@include sp{
	
					}//sp
				}//h1
			}//__logo
			
			&__snavi{
				width: vw(360);
				@include sp{
					width: auto;
				}//sp
				
				.icons{
					display: flex;
					flex-wrap: wrap;
					justify-content:center;
					column-gap: vw(38);
					margin: 0 0 vw(15);
					@include sp{
						margin: 0;
						column-gap: svw(5);
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: svw(10);
					}//sp
					
					li{
						&:nth-child(1),
						&:nth-child(3){
							@include sp{
								display: none;
							}//sp
						}//
						
						figure{
							margin: 0 0 vw(5);
							@include sp{
								margin: 0 0 svw(5);
							}//sp
							
							img{
								width: auto;
								height: vw(35);
								@include sp{
									height: svw(18);
								}//sp
							}//img
						}//figure
						
						p{
							color: #fff;
							font-size: vw(14);
							font-weight: 600;
							letter-spacing: .03em;
							line-height: 1;
							@include sp{
								color: #535353;
								font-size: svw(10);
								font-weight: 400;
							}//sp
						}//p
					}//li
				}//.icons
				
				.search{
					display: flex;
					flex-wrap: wrap;
					position: relative;
					@include sp{
						display: none;
					}//sp
					
					.icon{
						width: vw(20);
						position: absolute;
						top: 50%;
						left: vw(8);
						transform: translateY(-50%); 
						
						img{
							width: 100%;
							height: auto;
						}//img
					}//.icon
					
					input[type="text"]{
						font-size: vw(14);
						font-weight: 600;
						letter-spacing: .03em;
						line-height: 1;
						width: vw(280);
						height: vw(35);
						padding: 0 vw(10) 0 vw(40);
						appearance: none;
						background: #fff;
						border: none;
						border-radius: 0;
						outline: none;
						box-sizing: border-box;
					}//input[type="text"]
					
					a{
						color: #fff;
						font-size: vw(14);
						font-weight: 600;
						letter-spacing: .03em;
						line-height: 1;
						width: vw(80);
						height: vw(35);
						background-color: #535353;
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						align-items: center;
					}//a
				}//.search
			}//__snavi
			
			.sp-search{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: svw(60);
				display: none;
				@include sp{
					display: block;
				}//sp
				
				a{
					display: block;
					
					figure{
						margin: 0 0 vw(5);
						@include sp{
							margin: 0 0 svw(5);
						}//sp
						
						img{
							width: auto;
							height: vw(35);
							@include sp{
								height: svw(18);
							}//sp
						}//img
					}//figure
					
					p{
						color: #535353;
						font-size: vw(14);
						font-weight: 600;
						letter-spacing: .03em;
						line-height: 1;
						@include sp{
							font-size: svw(10);
							font-weight: 400;
						}//sp
					}//p
				}//a
			}//.sp-search
			
			.sp-menu-btn{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: svw(15);
				display: none;
				@include sp{
					display: block;
				}//sp
				
				a{
					display: block;
					
					&:hover{
						opacity: 1;
					}//:hover
					
					figure{
						img{
							width: svw(30);
							vertical-align: bottom;
							
							&:nth-of-type(2){
								display: none;
							}//:nth-of-type(2)
						}//img
					}//figure
					
					&.is_active{
						figure{
							img{
								display: none;
								
								&:nth-of-type(2){
									display: block;
								}//:nth-of-type(2)
							}//img
						}//figure
					}//.is_active
				}//a
			}//.sp-menu-btn
		}//__main
	}//__inner
	
	&__gnavi{
		width: 100%;
		background-color: #421f1b;
		position: relative;
		z-index: 2;
		@include sp{
			display: none;
		}//sp
		
		ul{
			width: vw(1000);
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			
			>li{
				width: calc(100% / 7);
				position: relative;
				
				&:last-child{
					&:after{
						display: none;
					}//:after
				}//:last-child
				
				&:after{
					content: "";
					width: vw(1);
					height: vw(16);
					background-color: #fff;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
				}//:after
				
				a{
					font-size: vw(16);
					font-weight: 600;
					padding: vw(10) 0;
					display: block;
					color: #fff;
				}//a
				
				&:hover{
					ol{
						top: vw(35);
						width: vw(200);
						padding: vw(10) 0;
						visibility: visible;
						opacity: 1;
					}//ol
				}//:hover
				
				ol{
					width: vw(180);
					height: auto;
					background-color: #d9c691;
					opacity: 0;
					position: absolute;
					left: 0;
					top: vw(30);
					visibility: hidden;
					transition: .5s;
					z-index: 10;
					
					li{
						position: relative;
						text-align: left;
						
						a{
							font-size: vw(13);
							font-weight: 400;
							padding: vw(9) vw(10) vw(12) vw(14);
							display: block;
							
							&::before {
								content: "＞";
								color: #e4ddc0;
								font-size: vw(13);
								margin: 0 vw(5) 0 0;
							}//::before {
						}//a
					}//li
				}//ol
			}//li
		}//ul
		
		&.is_active{
			position: fixed;
			top: 0;
			left: 0;
		}//.is_active
	}//__gnavi
}//.p-header-container