@charset "UTF-8";
/*----------------------------------------
	Web Fonts
----------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Zen+Kaku+Gothic+New:400,500,700,900&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css?family=Shippori+Mincho:400,500,600,700,800&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css?family=Outfit:200,300,400,500,600,700&display=swap");
/*----------------------------------------
	Fonts
----------------------------------------*/
/*----------------------------------------
	Common
----------------------------------------*/
/*
html{
	scroll-behavior: smooth;
}//html
*/
body {
  /*
  color: #4A4847;
  font-family: $fontGothic;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  word-break: break-word;
  height: 100%;
  background-color: #fafafa;
  overflow-x: hidden;

  &.is_fixed{
  	overflow: hidden;
  }//is_fixed
  */
  min-width: 1024px;
}
@media (max-width: 768px) {
  body {
    min-width: 100%;
    padding: 24vw 0 0 !important;
  }
}

/*
a{
	color: #222;
	text-decoration: none;
	transition: all .6s ease;
}//a

img{
	vertical-align: bottom;
	-webkit-backface-visibility: hidden;
}//img
*/
/* スマホサイドメニュー */
#sideMenu {
  display: none;
  top: 16vw !important;
  right: auto;
  left: -100vw;
  transition: all 0.6s ease;
}
@media (max-width: 768px) {
  #sideMenu {
    display: block;
  }
}
#sideMenu.is_active {
  left: 0;
}

/* フッターナビ */
@media (max-width: 768px) {
  .l-new-footer02 {
    display: none;
  }
}

.c-modal-menu {
  width: 100%;
  height: 100%;
  background: transparent url("../images/common/noise_bg.png") repeat;
  background-size: min(25000vw, 250px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 95;
  overflow: scroll;
  display: none;
}
.c-modal-menu__inner {
  width: 100%;
  height: 100%;
  position: relative;
}
.c-modal-menu__inner__logo {
  position: absolute;
  top: 4vw;
  left: 5.3333333333vw;
}
.c-modal-menu__inner__logo a {
  display: block;
}
.c-modal-menu__inner__logo a:hover {
  opacity: 0.6;
}
.c-modal-menu__inner__logo a img {
  width: auto;
  height: 13.3333333333vw;
}
.c-modal-menu__inner__gnavi {
  width: 100%;
  position: absolute;
  top: 42%;
  transform: translateY(-50%);
}
.c-modal-menu__inner__gnavi ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  row-gap: 13.3333333333vw;
}
.c-modal-menu__inner__gnavi ul li a {
  color: #fff;
  font-size: 5.3333333333vw;
  font-weight: 600;
  letter-spacing: 0.15em;
  line-height: 1;
}
.c-modal-menu__inner__contact {
  position: absolute;
  left: 50%;
  bottom: 24vw;
  transform: translateX(-50%);
}
.c-modal-menu__inner__contact a {
  width: 77.3333333333vw;
  display: block;
}
.c-modal-menu__inner__contact a:hover {
  opacity: 0.6;
}
.c-modal-menu__inner__contact a img {
  width: 100%;
  height: auto;
}
.c-modal-menu__inner__copyright {
  color: #fff;
  font-size: 3.7333333333vw;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 10.6666666667vw;
}

.c-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: all 0.6s ease;
  overflow: scroll;
}
.c-modal::-webkit-scrollbar {
  display: none;
}
.c-modal.is_active {
  opacity: 1;
  pointer-events: auto;
}
.c-modal__overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(171, 171, 171, 0.9);
  position: fixed;
  top: 0;
  left: 0;
}
.c-modal__inner {
  width: min(90000vw, 900px);
  padding: min(3000vw, 30px);
  background-color: #fff;
  border-radius: min(600vw, 6px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .c-modal__inner {
    width: 93.3333333333vw;
    padding: 0;
    border-radius: 1.6vw;
    top: 21.3333333333vw;
    transform: translateX(-50%);
    display: block;
  }
}
.c-modal__inner figure {
  width: min(40000vw, 400px);
}
@media (max-width: 768px) {
  .c-modal__inner figure {
    width: auto;
  }
}
.c-modal__inner figure img {
  width: 100%;
  height: auto;
  border-radius: min(600vw, 6px);
}
@media (max-width: 768px) {
  .c-modal__inner figure img {
    height: 69.3333333333vw;
    border-radius: 1.6vw 1.6vw 0 0;
    object-fit: cover;
  }
}
.c-modal__inner .detail {
  width: min(41000vw, 410px);
}
@media (max-width: 768px) {
  .c-modal__inner .detail {
    width: auto;
    padding: 5.3333333333vw;
  }
}
.c-modal__inner .detail .heading {
  margin: 0 0 min(3000vw, 30px);
}
@media (max-width: 768px) {
  .c-modal__inner .detail .heading {
    margin: 0 0 2.6666666667vw;
  }
}
.c-modal__inner .detail .heading .position {
  font-size: min(1600vw, 16px);
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.3;
  margin: 0 0 min(500vw, 5px);
}
@media (max-width: 768px) {
  .c-modal__inner .detail .heading .position {
    font-size: 3.7333333333vw;
    margin: 0 0 0.8vw;
  }
}
.c-modal__inner .detail .heading .company {
  font-size: min(1600vw, 16px);
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.3;
  margin: 0 0 min(2000vw, 20px);
}
@media (max-width: 768px) {
  .c-modal__inner .detail .heading .company {
    font-size: 3.7333333333vw;
    margin: 0 0 1.3333333333vw;
  }
}
.c-modal__inner .detail .heading .name {
  font-size: min(2600vw, 26px);
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.3;
}
@media (max-width: 768px) {
  .c-modal__inner .detail .heading .name {
    font-size: 6.4vw;
  }
}
.c-modal__inner .detail .meta {
  margin: 0 0 min(3000vw, 30px);
}
@media (max-width: 768px) {
  .c-modal__inner .detail .meta {
    margin: 0 0 5.3333333333vw;
  }
}
.c-modal__inner .detail .meta li {
  margin: 0 0 min(500vw, 5px);
}
@media (max-width: 768px) {
  .c-modal__inner .detail .meta li {
    margin: 0 0 1.3333333333vw;
  }
}
.c-modal__inner .detail .meta li:last-child {
  margin: 0;
}
.c-modal__inner .detail .meta li dl {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .c-modal__inner .detail .meta li dl {
    justify-content: flex-start;
  }
}
.c-modal__inner .detail .meta li dl dt {
  color: #C0C0C0;
  font-size: min(1200vw, 12px);
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.3;
}
@media (max-width: 768px) {
  .c-modal__inner .detail .meta li dl dt {
    font-size: 2.9333333333vw;
    width: 20vw;
  }
}
.c-modal__inner .detail .meta li dl dd {
  font-size: min(1600vw, 16px);
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.5;
  width: min(32000vw, 320px);
}
@media (max-width: 768px) {
  .c-modal__inner .detail .meta li dl dd {
    font-size: 3.2vw;
    width: auto;
  }
}
.c-modal__inner .detail .desc {
  font-size: min(1600vw, 16px);
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.6;
}
@media (max-width: 768px) {
  .c-modal__inner .detail .desc {
    font-size: 4.2666666667vw;
    line-height: 1.5;
  }
}
.c-modal__inner.is_adjust {
  align-items: flex-start;
}
@media (max-width: 768px) {
  .c-modal__inner.is_adjust figure {
    padding: 5.3333333333vw 5.3333333333vw 0;
  }
}
@media (max-width: 768px) {
  .c-modal__inner.is_adjust figure img {
    border-radius: 1.6vw;
  }
}
.c-modal__inner.is_adjust .detail {
  padding: min(1000vw, 10px) 0;
}
@media (max-width: 768px) {
  .c-modal__inner.is_adjust .detail {
    padding: 5.3333333333vw;
  }
}
.c-modal__inner.is_adjust .detail .meta li {
  margin: 0 0 min(1500vw, 15px);
}
@media (max-width: 768px) {
  .c-modal__inner.is_adjust .detail .meta li {
    margin: 0 0 2.1333333333vw;
  }
}
.c-modal__inner.is_adjust .detail .meta li:last-child {
  margin: 0;
}
.c-modal__inner.is_adjust .detail .meta li dl {
  display: block;
}
.c-modal__inner.is_adjust .detail .meta li dl dt {
  margin: 0 0 min(500vw, 5px);
}
@media (max-width: 768px) {
  .c-modal__inner.is_adjust .detail .meta li dl dt {
    width: auto;
    margin: 0 0 1.3333333333vw;
  }
}
.c-modal__inner.is_adjust .detail .meta li dl dd {
  width: min(33000vw, 330px);
}
@media (max-width: 768px) {
  .c-modal__inner.is_adjust .detail .meta li dl dd {
    font-size: 4.2666666667vw;
    width: auto;
  }
}
.c-modal__inner.is_adjust .detail .meta li dl dd.name, .c-modal__inner.is_adjust .detail .meta li dl dd.alt {
  font-size: min(1800vw, 18px);
}
@media (max-width: 768px) {
  .c-modal__inner.is_adjust .detail .meta li dl dd.name, .c-modal__inner.is_adjust .detail .meta li dl dd.alt {
    font-size: 4.2666666667vw;
  }
}
.c-modal__inner__close {
  width: min(4400vw, 44px);
  position: absolute;
  top: 0;
  right: calc(0% - min(6400vw, 64px));
  cursor: pointer;
}
@media (max-width: 768px) {
  .c-modal__inner__close {
    width: 11.7333333333vw;
    top: -16vw;
    right: 0;
  }
}
.c-modal__inner__close img {
  width: 100%;
  height: auto;
  transition: transform 0.6s ease;
}
.c-modal__inner__close:hover img {
  transform: rotate(180deg);
}

.c-section-title {
  margin: 0 0 min(5000vw, 50px);
}
@media (max-width: 768px) {
  .c-section-title {
    margin: 0 0 6.6666666667vw;
  }
}
.c-section-title .en {
  text-align: center;
  margin: 0 0 min(2000vw, 20px);
}
@media (max-width: 768px) {
  .c-section-title .en {
    margin: 0 0 4vw;
  }
}
.c-section-title .en span {
  color: #BA9327;
  font-size: min(2600vw, 26px);
  font-weight: 100;
  letter-spacing: 0;
  line-height: 1;
  text-align: center;
  border-bottom: 2px solid #BA9327;
}
@media (max-width: 768px) {
  .c-section-title .en span {
    font-size: 3.4666666667vw;
    border-width: 0.2666666667vw;
  }
}
.c-section-title h3 {
  color: #535353;
  font-size: min(4600vw, 46px);
  font-weight: 600;
  text-align: center;
}
@media (max-width: 768px) {
  .c-section-title h3 {
    font-size: 6.9333333333vw;
  }
}

.p-header-container {
  background-color: #421f1b;
}
@media (max-width: 768px) {
  .p-header-container {
    width: 100%;
    height: 16vw;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
}
.p-header-container__inner {
  width: min(100000vw, 1000px);
  margin: 0 auto;
}
@media (max-width: 768px) {
  .p-header-container__inner {
    width: min(100000vw, 1000px);
    margin: 0 auto;
    width: auto;
    padding: 3.2vw 4vw;
  }
}
.p-header-container__inner__top {
  padding: min(1000vw, 10px) 0 min(3000vw, 30px);
  display: flex;
  justify-content: flex-start;
}
@media (max-width: 768px) {
  .p-header-container__inner__top {
    display: none;
  }
}
.p-header-container__inner__top .-point-dokuji td {
  color: #fff;
}
.p-header-container__inner__top p {
  color: #fff;
  font-size: min(1400vw, 14px);
  letter-spacing: 0.03em;
  line-height: 1;
  border-left: 1px solid #fff;
}
.p-header-container__inner__top p.guest {
  padding: 0 min(2000vw, 20px) 0 0;
  margin: 0 min(2000vw, 20px) 0 0;
  border-right: min(100vw, 1px) solid #fff;
}
.p-header-container__inner__top p.guest .point {
  padding: 0 min(300vw, 3px);
}
.p-header-container__inner__top p a {
  color: #fff;
}
.p-header-container__inner__top p a:hover {
  text-decoration: underline;
}
.p-header-container__inner__main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: min(1000vw, 10px) 0 min(500vw, 5px);
}
.p-header-container__inner__main__heading {
  width: min(54000vw, 540px);
  text-align: left;
}
@media (max-width: 768px) {
  .p-header-container__inner__main__heading {
    width: auto;
    margin: 0 auto;
  }
}
.p-header-container__inner__main__heading .logo {
  width: min(34000vw, 340px);
}
@media (max-width: 768px) {
  .p-header-container__inner__main__heading .logo {
    width: 45.3333333333vw;
  }
}
.p-header-container__inner__main__heading .logo img {
  width: 100%;
  height: auto;
}
.p-header-container__inner__main__heading h1 {
  color: #fff;
  font-size: min(1400vw, 14px);
  letter-spacing: 0.03em;
  line-height: 1.4;
}
.p-header-container__inner__main__snavi {
  width: min(36000vw, 360px);
}
@media (max-width: 768px) {
  .p-header-container__inner__main__snavi {
    width: auto;
  }
}
.p-header-container__inner__main__snavi .icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: min(3800vw, 38px);
  margin: 0 0 min(1500vw, 15px);
}
@media (max-width: 768px) {
  .p-header-container__inner__main__snavi .icons {
    margin: 0;
    column-gap: 1.3333333333vw;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2.6666666667vw;
  }
}
@media (max-width: 768px) {
  .p-header-container__inner__main__snavi .icons li:nth-child(1), .p-header-container__inner__main__snavi .icons li:nth-child(3) {
    display: none;
  }
}
.p-header-container__inner__main__snavi .icons li figure {
  margin: 0 0 min(500vw, 5px);
}
@media (max-width: 768px) {
  .p-header-container__inner__main__snavi .icons li figure {
    margin: 0 0 1.3333333333vw;
  }
}
.p-header-container__inner__main__snavi .icons li figure img {
  width: auto;
  height: min(3500vw, 35px);
}
@media (max-width: 768px) {
  .p-header-container__inner__main__snavi .icons li figure img {
    height: 4.8vw;
  }
}
.p-header-container__inner__main__snavi .icons li p {
  color: #fff;
  font-size: min(1400vw, 14px);
  font-weight: 600;
  letter-spacing: 0.03em;
  line-height: 1;
}
@media (max-width: 768px) {
  .p-header-container__inner__main__snavi .icons li p {
    color: #535353;
    font-size: 2.6666666667vw;
    font-weight: 400;
  }
}
.p-header-container__inner__main__snavi .search {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
@media (max-width: 768px) {
  .p-header-container__inner__main__snavi .search {
    display: none;
  }
}
.p-header-container__inner__main__snavi .search .icon {
  width: min(2000vw, 20px);
  position: absolute;
  top: 50%;
  left: min(800vw, 8px);
  transform: translateY(-50%);
}
.p-header-container__inner__main__snavi .search .icon img {
  width: 100%;
  height: auto;
}
.p-header-container__inner__main__snavi .search input[type=text] {
  font-size: min(1400vw, 14px);
  font-weight: 600;
  letter-spacing: 0.03em;
  line-height: 1;
  width: min(28000vw, 280px);
  height: min(3500vw, 35px);
  padding: 0 min(1000vw, 10px) 0 min(4000vw, 40px);
  appearance: none;
  background: #fff;
  border: none;
  border-radius: 0;
  outline: none;
  box-sizing: border-box;
}
.p-header-container__inner__main__snavi .search a {
  color: #fff;
  font-size: min(1400vw, 14px);
  font-weight: 600;
  letter-spacing: 0.03em;
  line-height: 1;
  width: min(8000vw, 80px);
  height: min(3500vw, 35px);
  background-color: #535353;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.p-header-container__inner__main .sp-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16vw;
  display: none;
}
@media (max-width: 768px) {
  .p-header-container__inner__main .sp-search {
    display: block;
  }
}
.p-header-container__inner__main .sp-search a {
  display: block;
}
.p-header-container__inner__main .sp-search a figure {
  margin: 0 0 min(500vw, 5px);
}
@media (max-width: 768px) {
  .p-header-container__inner__main .sp-search a figure {
    margin: 0 0 1.3333333333vw;
  }
}
.p-header-container__inner__main .sp-search a figure img {
  width: auto;
  height: min(3500vw, 35px);
}
@media (max-width: 768px) {
  .p-header-container__inner__main .sp-search a figure img {
    height: 4.8vw;
  }
}
.p-header-container__inner__main .sp-search a p {
  color: #535353;
  font-size: min(1400vw, 14px);
  font-weight: 600;
  letter-spacing: 0.03em;
  line-height: 1;
}
@media (max-width: 768px) {
  .p-header-container__inner__main .sp-search a p {
    font-size: 2.6666666667vw;
    font-weight: 400;
  }
}
.p-header-container__inner__main .sp-menu-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 4vw;
  display: none;
}
@media (max-width: 768px) {
  .p-header-container__inner__main .sp-menu-btn {
    display: block;
  }
}
.p-header-container__inner__main .sp-menu-btn a {
  display: block;
}
.p-header-container__inner__main .sp-menu-btn a:hover {
  opacity: 1;
}
.p-header-container__inner__main .sp-menu-btn a figure img {
  width: 8vw;
  vertical-align: bottom;
}
.p-header-container__inner__main .sp-menu-btn a figure img:nth-of-type(2) {
  display: none;
}
.p-header-container__inner__main .sp-menu-btn a.is_active figure img {
  display: none;
}
.p-header-container__inner__main .sp-menu-btn a.is_active figure img:nth-of-type(2) {
  display: block;
}
.p-header-container__gnavi {
  width: 100%;
  background-color: #421f1b;
  position: relative;
  z-index: 2;
}
@media (max-width: 768px) {
  .p-header-container__gnavi {
    display: none;
  }
}
.p-header-container__gnavi ul {
  width: min(100000vw, 1000px);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.p-header-container__gnavi ul > li {
  width: 14.2857142857%;
  position: relative;
}
.p-header-container__gnavi ul > li:last-child:after {
  display: none;
}
.p-header-container__gnavi ul > li:after {
  content: "";
  width: min(100vw, 1px);
  height: min(1600vw, 16px);
  background-color: #fff;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.p-header-container__gnavi ul > li a {
  font-size: min(1600vw, 16px);
  font-weight: 600;
  padding: min(1000vw, 10px) 0;
  display: block;
  color: #fff;
}
.p-header-container__gnavi ul > li:hover ol {
  top: min(3500vw, 35px);
  width: min(20000vw, 200px);
  padding: min(1000vw, 10px) 0;
  visibility: visible;
  opacity: 1;
}
.p-header-container__gnavi ul > li ol {
  width: min(18000vw, 180px);
  height: auto;
  background-color: #d9c691;
  opacity: 0;
  position: absolute;
  left: 0;
  top: min(3000vw, 30px);
  visibility: hidden;
  transition: 0.5s;
  z-index: 10;
}
.p-header-container__gnavi ul > li ol li {
  position: relative;
  text-align: left;
}
.p-header-container__gnavi ul > li ol li a {
  font-size: min(1300vw, 13px);
  font-weight: 400;
  padding: min(900vw, 9px) min(1000vw, 10px) min(1200vw, 12px) min(1400vw, 14px);
  display: block;
}
.p-header-container__gnavi ul > li ol li a::before {
  content: "＞";
  color: #e4ddc0;
  font-size: min(1300vw, 13px);
  margin: 0 min(500vw, 5px) 0 0;
}
.p-header-container__gnavi.is_active {
  position: fixed;
  top: 0;
  left: 0;
}

.p-index-blog {
  margin: 0 0 min(10000vw, 100px);
}
@media (max-width: 768px) {
  .p-index-blog {
    margin: 0 0 13.3333333333vw;
  }
}
.p-index-blog__inner {
  width: min(100000vw, 1000px);
  padding: 0 min(4000vw, 40px);
  margin: 0 auto;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .p-index-blog__inner {
    width: auto;
    padding: 0 5.3333333333vw;
  }
}
.p-index-blog__inner ul li {
  margin: 0 0 min(2000vw, 20px);
  position: relative;
}
@media (max-width: 768px) {
  .p-index-blog__inner ul li {
    margin: 0 0 4vw;
  }
}
.p-index-blog__inner ul li::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 min(1100vw, 11px) min(1200vw, 12px) min(1100vw, 11px);
  border-color: transparent transparent #E13035 transparent;
  position: absolute;
  left: calc(0% - min(800vw, 8px));
  top: calc(0% - min(200vw, 2px));
  transform: rotate(-45deg);
  z-index: 1;
}
@media (max-width: 768px) {
  .p-index-blog__inner ul li::before {
    border-width: 0 1.4666666667vw 1.6vw 1.4666666667vw;
    left: -0.5333333333vw;
    top: -0.2666666667vw;
  }
}
.p-index-blog__inner ul li::after {
  content: "";
  width: min(1400vw, 14px);
  height: min(1400vw, 14px);
  border-top: min(300vw, 3px) solid #e13035;
  border-right: min(300vw, 3px) solid #e13035;
  display: block;
  position: absolute;
  top: 50%;
  right: min(5000vw, 50px);
  transform: translateY(-50%) rotate(45deg);
}
@media (max-width: 768px) {
  .p-index-blog__inner ul li::after {
    width: 2.1333333333vw;
    height: 2.1333333333vw;
    border-width: 0.2666666667vw;
    display: block;
    position: absolute;
    top: 50%;
    right: 5.3333333333vw;
  }
}
.p-index-blog__inner ul li a {
  font-size: min(2000vw, 20px);
  font-weight: 600;
  line-height: 1;
  text-align: left;
  padding: min(2000vw, 20px) min(3000vw, 30px);
  border: min(400vw, 4px) solid #e1dccf;
  display: block;
}
@media (max-width: 768px) {
  .p-index-blog__inner ul li a {
    font-size: 4.8vw;
    line-height: 1.4;
    padding: 2.1333333333vw 8vw 2.1333333333vw 4vw;
    border-width: 0.5333333333vw;
  }
}

.p-index-category {
  margin: 0 0 min(10000vw, 100px);
}
@media (max-width: 768px) {
  .p-index-category {
    margin: 0 0 13.3333333333vw;
  }
}
.p-index-category__inner {
  width: min(100000vw, 1000px);
  padding: 0 min(4000vw, 40px);
  margin: 0 auto;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .p-index-category__inner {
    width: auto;
    padding: 0 0 0 5.3333333333vw;
    overflow: scroll;
  }
}
.p-index-category__inner .simplebar-track {
  display: none !important;
}
@media (max-width: 768px) {
  .p-index-category__inner .simplebar-track {
    display: block !important;
  }
}
@media (max-width: 768px) {
  .p-index-category__inner .scroll-x {
    overflow: scroll;
  }
}
.p-index-category__inner .scroll-x ul {
  display: flex;
  flex-wrap: wrap;
  column-gap: min(2000vw, 20px);
  row-gap: min(2000vw, 20px);
}
@media (max-width: 768px) {
  .p-index-category__inner .scroll-x ul {
    width: max-content;
    flex-wrap: nowrap;
    column-gap: 5.3333333333vw;
    row-gap: 0;
    margin: 0 0 6.6666666667vw;
  }
}
.p-index-category__inner .scroll-x ul li {
  width: calc((100% - min(6000vw, 60px)) / 4);
}
@media (max-width: 768px) {
  .p-index-category__inner .scroll-x ul li {
    width: 33.3333333333vw;
  }
}
.p-index-category__inner .scroll-x ul li a {
  display: block;
}
.p-index-category__inner .scroll-x ul li a figure {
  margin: 0 0 min(2000vw, 20px);
}
@media (max-width: 768px) {
  .p-index-category__inner .scroll-x ul li a figure {
    margin: 0 0 4vw;
  }
}
.p-index-category__inner .scroll-x ul li a figure img {
  width: 100%;
  height: auto;
  border-radius: min(3000vw, 30px);
}
@media (max-width: 768px) {
  .p-index-category__inner .scroll-x ul li a figure img {
    border-radius: 4vw;
  }
}
.p-index-category__inner .scroll-x ul li a p {
  color: #525252;
  font-size: min(1600vw, 16px);
  font-weight: 600;
  letter-spacing: 0.03em;
  line-height: 1;
  text-align: center;
}
@media (max-width: 768px) {
  .p-index-category__inner .scroll-x ul li a p {
    font-size: 3.7333333333vw;
  }
}
.p-index-category__inner .scroll-x .simplebar-track {
  background-color: #f1e5c6;
  border-radius: 7px;
}
.p-index-category__inner .scroll-x .simplebar-scrollbar::before {
  background-color: #b79027;
}

.event_bnr {
  max-width: min(100000vw, 1000px);
  margin: 0 auto;
}
.event_bnr.pc {
  margin-top: min(2000vw, 20px);
}
.event_bnr.sp {
  margin-bottom: min(1000vw, 10px);
}

.p-index-important {
  padding: min(2000vw, 20px) 0;
}
@media (max-width: 768px) {
  .p-index-important {
    padding: 0;
  }
}
.p-index-important__inner {
  width: min(100000vw, 1000px);
  margin: 0 auto;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .p-index-important__inner {
    width: auto;
  }
}
.p-index-important__inner dl dt {
  color: #fff;
  font-size: min(2400vw, 24px);
  font-weight: 600;
  line-height: 1;
  height: min(4000vw, 40px);
  background-color: #e13035;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
@media (max-width: 768px) {
  .p-index-important__inner dl dt {
    font-size: 4vw;
    font-weight: 600;
    line-height: 1;
    height: 10.6666666667vw;
  }
}
.p-index-important__inner dl dt .icon {
  width: min(2400vw, 24px);
  height: min(2400vw, 24px);
  background-color: #fff;
  border-radius: min(500vw, 5px);
  position: absolute;
  top: 50%;
  right: min(3000vw, 30px);
  transform: translateY(-50%);
}
@media (max-width: 768px) {
  .p-index-important__inner dl dt .icon {
    width: 5.3333333333vw;
    height: 5.3333333333vw;
    border-radius: 1.3333333333vw;
    right: 4vw;
  }
}
.p-index-important__inner dl dt .icon:before, .p-index-important__inner dl dt .icon:after {
  content: "";
  width: min(1600vw, 16px);
  height: min(200vw, 2px);
  background-color: #e13035;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 768px) {
  .p-index-important__inner dl dt .icon:before, .p-index-important__inner dl dt .icon:after {
    width: 2.6666666667vw;
    height: 0.5333333333vw;
  }
}
.p-index-important__inner dl dt .icon:after {
  width: min(200vw, 2px);
  height: min(1600vw, 16px);
}
@media (max-width: 768px) {
  .p-index-important__inner dl dt .icon:after {
    width: 0.5333333333vw;
    height: 2.6666666667vw;
  }
}
.p-index-important__inner dl dt.is_active .icon:after {
  display: none;
}
.p-index-important__inner dl dd {
  display: none;
}
.p-index-important__inner dl dd ul {
  text-align: left;
  padding: min(2000vw, 20px) min(4000vw, 40px) 0;
}
@media (max-width: 768px) {
  .p-index-important__inner dl dd ul {
    padding: 2.6666666667vw 2.6666666667vw 4vw;
  }
}
.p-index-important__inner dl dd ul li {
  margin: 0 0 min(800vw, 8px);
}
@media (max-width: 768px) {
  .p-index-important__inner dl dd ul li {
    margin: 0 0 1.3333333333vw;
  }
}
.p-index-important__inner dl dd ul li a {
  font-size: min(1800vw, 18px);
  line-height: 1.4;
  text-decoration: underline;
}
@media (max-width: 768px) {
  .p-index-important__inner dl dd ul li a {
    font-size: 4.8vw;
  }
}
.p-index-important__inner dl dd ul li a:hover {
  text-decoration: none;
}

.p-index-information {
  margin: 0 0 min(10000vw, 100px);
}
@media (max-width: 768px) {
  .p-index-information {
    margin: 0 0 13.3333333333vw;
  }
}
.p-index-information__inner {
  width: min(100000vw, 1000px);
  padding: 0 min(10000vw, 100px);
  margin: 0 auto;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .p-index-information__inner {
    width: auto;
    padding: 0 10.6666666667vw;
  }
}
.p-index-information__inner ul {
  text-align: left;
  margin: 0 0 min(2000vw, 20px);
}
@media (max-width: 768px) {
  .p-index-information__inner ul {
    margin: 0;
    border-bottom: 0.2666666667vw dotted #ccc;
  }
}
.p-index-information__inner ul li {
  padding: min(2000vw, 20px) 0;
  border-bottom: min(100vw, 1px) dotted #ccc;
  display: flex;
  flex-wrap: wrap;
  column-gap: min(3000vw, 30px);
}
@media (max-width: 768px) {
  .p-index-information__inner ul li {
    padding: 0;
    margin: 0 0 6.6666666667vw;
    border: none;
    display: block;
  }
}
.p-index-information__inner ul li:last-child {
  border-bottom: none;
}
.p-index-information__inner ul li:nth-child(4), .p-index-information__inner ul li:nth-child(5) {
  display: none;
}
.p-index-information__inner ul li .date {
  color: #535353;
  font-size: min(1400vw, 14px);
  line-height: 1.2;
}
@media (max-width: 768px) {
  .p-index-information__inner ul li .date {
    font-size: 3.7333333333vw;
    line-height: 1.4;
    display: block;
  }
}
.p-index-information__inner ul li a {
  color: #535353;
  font-size: min(1600vw, 16px);
  line-height: 1.2;
  max-width: min(67000vw, 670px);
}
@media (max-width: 768px) {
  .p-index-information__inner ul li a {
    font-size: 4.8vw;
    line-height: 1.4;
    max-width: 100%;
  }
}
.p-index-information__inner ul li a:hover {
  opacity: 0.6;
}
.p-index-information__inner .viewmore {
  text-align: right;
}
@media (max-width: 768px) {
  .p-index-information__inner .viewmore {
    margin: 5.3333333333vw 0 0;
  }
}
.p-index-information__inner .viewmore a {
  font-size: min(1600vw, 16px);
  line-height: 1;
}
@media (max-width: 768px) {
  .p-index-information__inner .viewmore a {
    font-size: 4.8vw;
  }
}

.p-index-main-visual {
  margin: 0 0 min(6000vw, 60px);
  overflow: hidden;
}
@media (max-width: 768px) {
  .p-index-main-visual {
    margin: 0 0 13.3333333333vw;
  }
}
.p-index-main-visual:hover .slick-arrow {
  opacity: 1;
}
.p-index-main-visual:hover .slick-arrow.prev {
  left: calc(0% - min(4000vw, 40px));
}
.p-index-main-visual:hover .slick-arrow.next {
  right: calc(0% - min(4000vw, 40px));
}
.p-index-main-visual__inner {
  width: min(100000vw, 1000px);
  margin: 0 auto;
}
@media (max-width: 768px) {
  .p-index-main-visual__inner {
    width: auto;
  }
}
.p-index-main-visual__inner .slide p {
  font-size: min(1800vw, 18px);
  line-height: 1;
  text-align: center;
  margin: min(1000vw, 10px) 0 0;
}
@media (max-width: 768px) {
  .p-index-main-visual__inner .slide p {
    font-size: 4.2666666667vw;
    margin: 4vw 0 0;
  }
}
.p-index-main-visual__inner .slick-arrow {
  line-height: 0;
  position: absolute;
  top: min(25000vw, 250px);
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s ease;
}
@media (max-width: 768px) {
  .p-index-main-visual__inner .slick-arrow {
    display: none !important;
  }
}
.p-index-main-visual__inner .slick-arrow:hover {
  opacity: 0.3;
}
.p-index-main-visual__inner .slick-arrow.prev {
  left: calc(0% - min(2000vw, 20px));
}
.p-index-main-visual__inner .slick-arrow.next {
  right: calc(0% - min(2000vw, 20px));
}
.p-index-main-visual__inner .slick-arrow svg {
  width: min(1600vw, 16px);
  height: auto;
}
.p-index-main-visual__inner .slick-dots {
  margin: min(3000vw, 30px) 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: min(1500vw, 15px);
}
@media (max-width: 768px) {
  .p-index-main-visual__inner .slick-dots {
    margin: 8vw 0 0;
    column-gap: 2.1333333333vw;
  }
}
.p-index-main-visual__inner .slick-dots li {
  line-height: 0;
  position: relative;
}
.p-index-main-visual__inner .slick-dots li button {
  color: transparent;
  font-size: 0;
  line-height: 0;
  width: min(1000vw, 10px);
  height: min(1000vw, 10px);
  padding: 0;
  background-color: #cbcbcb;
  border-radius: 100%;
  transition: all 0.6s ease;
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
}
@media (max-width: 768px) {
  .p-index-main-visual__inner .slick-dots li button {
    width: 2.6666666667vw;
    height: 2.6666666667vw;
  }
}
.p-index-main-visual__inner .slick-dots li.slick-active button {
  background-color: #535353;
}

.p-index-message {
  margin: 0 0 min(10000vw, 100px);
}
@media (max-width: 768px) {
  .p-index-message {
    margin: 0 0 13.3333333333vw;
  }
}
.p-index-message__inner {
  width: min(100000vw, 1000px);
  padding: min(5000vw, 50px) min(4000vw, 40px);
  margin: 0 auto;
  box-sizing: border-box;
  background: #f1eeee;
}
@media (max-width: 768px) {
  .p-index-message__inner {
    width: auto;
    padding: 6.6666666667vw 0vw;
  }
}
.p-index-message__inner h3 {
  margin: 0 0 min(3000vw, 30px);
  line-height: 1.4;
  font-size: min(3000vw, 30px);
  font-weight: 700;
  text-align: center;
  color: #000;
  font-family: "Noto Sans JP", Sans-Serif;
}
@media (max-width: 768px) {
  .p-index-message__inner h3 {
    font-size: 6.9333333333vw;
  }
}
.p-index-message__inner .panel {
  margin: 0 0 min(5000vw, 50px);
  background: #fff;
  padding: min(5000vw, 50px) min(6000vw, 60px) min(12000vw, 120px);
  border-radius: 0 0 min(21000vw, 210px) min(21000vw, 210px);
}
@media (max-width: 768px) {
  .p-index-message__inner .panel {
    padding: 6.6666666667vw 8vw 16vw;
    border-radius: 0 0 26.6666666667vw 26.6666666667vw;
  }
}
.p-index-message__inner .panel figure {
  margin: 0 auto min(3000vw, 30px);
  max-width: 240px;
  text-align: center;
}
.p-index-message__inner .panel dl {
  margin: 0 0 min(3000vw, 30px);
}
.p-index-message__inner .panel dl dt {
  margin: 0 0 min(2000vw, 20px);
  line-height: 1.4;
  font-size: min(2200vw, 22px);
  font-weight: 400;
  text-align: center;
  font-family: "Noto Sans JP", Sans-Serif;
  color: #ff6600;
}
@media (max-width: 768px) {
  .p-index-message__inner .panel dl dt {
    font-size: 4.8vw;
  }
}
.p-index-message__inner .panel dl dd {
  font-family: "Noto Serif JP", serif;
  font-weight: 800;
  color: #4d4d4d;
  font-size: min(3300vw, 33px);
}
@media (max-width: 768px) {
  .p-index-message__inner .panel dl dd {
    font-size: 7.7333333333vw;
  }
}
.p-index-message__inner .panel dl dd span {
  display: block;
  margin: min(1000vw, 10px) 0 0;
  font-size: min(1800vw, 18px);
  color: #4d4d4d;
  font-weight: 400;
  font-family: "Noto Sans JP", Sans-Serif;
}
@media (max-width: 768px) {
  .p-index-message__inner .panel dl dd span {
    font-size: 3.7333333333vw;
  }
}
.p-index-message__inner .panel p {
  line-height: 1.8;
  font-size: min(2000vw, 20px);
  font-weight: 400;
  text-align: left;
  font-family: "Noto Sans JP", Sans-Serif;
  color: #4d4d4d;
}
@media (max-width: 768px) {
  .p-index-message__inner .panel p {
    line-height: 1.4;
    font-size: 4.2666666667vw;
  }
}
.p-index-message__inner section:nth-of-type(2) {
  margin: min(9000vw, 90px) 0 0;
}
@media (max-width: 768px) {
  .p-index-message__inner section {
    padding: 0vw 6.6666666667vw;
  }
}
.p-index-message__inner section h4 {
  margin: 0 0 min(2000vw, 20px);
  padding: 0 0 min(1000vw, 10px);
  font-size: min(2600vw, 26px);
  font-weight: 700;
  text-align: left;
  color: #000;
  font-family: "Noto Sans JP", Sans-Serif;
  border-bottom: 1px solid #fff;
}
@media (max-width: 768px) {
  .p-index-message__inner section h4 {
    font-size: 5.8666666667vw;
  }
}
.p-index-message__inner section p {
  margin: 0 0 min(2000vw, 20px);
  line-height: 1.8;
  font-size: min(2000vw, 20px);
  font-weight: 400;
  text-align: left;
  font-family: "Noto Sans JP", Sans-Serif;
  color: #000;
}
@media (max-width: 768px) {
  .p-index-message__inner section p {
    line-height: 1.4;
    font-size: 4.2666666667vw;
  }
}
.p-index-message__inner section ul {
  display: flex;
  justify-content: space-between;
  gap: 0 30px;
}
.p-index-message__inner section ul li img {
  max-height: 190px;
}
@media (max-width: 768px) {
  .p-index-message__inner section ul {
    flex-wrap: wrap;
    gap: 0 0;
  }
  .p-index-message__inner section ul li {
    flex-basis: 49%;
    background: #fff;
    margin: 0 0 1.3333333333vw;
    text-align: center;
    padding: 1.3333333333vw 2.6666666667vw;
  }
  .p-index-message__inner section ul li img {
    max-height: 190px;
  }
}

.p-index-problems {
  margin: 0 0 min(10000vw, 100px);
}
@media (max-width: 768px) {
  .p-index-problems {
    margin: 0 0 13.3333333333vw;
  }
}
.p-index-problems__inner {
  width: min(100000vw, 1000px);
  padding: 0 min(4000vw, 40px);
  margin: 0 auto;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .p-index-problems__inner {
    width: auto;
    padding: 0 5.3333333333vw;
  }
}
.p-index-problems__inner ul {
  display: flex;
  flex-wrap: wrap;
  column-gap: min(1000vw, 10px);
}
@media (max-width: 768px) {
  .p-index-problems__inner ul {
    display: block;
  }
}
.p-index-problems__inner ul li {
  width: min(30000vw, 300px);
}
@media (max-width: 768px) {
  .p-index-problems__inner ul li {
    width: auto;
    margin: 0 0 4vw;
  }
}
.p-index-problems__inner ul li:last-child {
  margin: 0;
}
.p-index-problems__inner ul li a {
  padding: min(500vw, 5px) min(500vw, 5px) 0;
  background-color: #b79027;
  display: block;
  position: relative;
}
@media (max-width: 768px) {
  .p-index-problems__inner ul li a {
    padding: 1.3333333333vw;
    background-color: transparent;
    border: 0.5333333333vw solid #b79027;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.3333333333vw;
  }
}
@media (max-width: 768px) {
  .p-index-problems__inner ul li a figure {
    width: 23.4666666667vw;
  }
}
.p-index-problems__inner ul li a figure img {
  width: 100%;
  height: auto;
}
.p-index-problems__inner ul li a h4 {
  color: #fff;
  font-size: min(3600vw, 36px);
  font-weight: 600;
  line-height: 1.4;
  padding: min(2000vw, 20px);
}
@media (max-width: 768px) {
  .p-index-problems__inner ul li a h4 {
    font-size: 5.3333333333vw;
    width: 60.8vw;
    padding: 0 2.6666666667vw;
    background-color: #b79027;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
  }
}
.p-index-problems__inner ul li a svg {
  width: min(1200vw, 12px);
  height: auto;
  position: absolute;
  bottom: min(3000vw, 30px);
  right: min(2500vw, 25px);
}
@media (max-width: 768px) {
  .p-index-problems__inner ul li a svg {
    width: 1.6vw;
    bottom: auto;
    top: 50%;
    right: 4vw;
    transform: translateY(-50%);
  }
}

.p-index-ranking {
  padding: min(5000vw, 50px) 0;
  margin: 0 0 min(10000vw, 100px);
}
@media (max-width: 768px) {
  .p-index-ranking {
    padding: 6.6666666667vw 0;
    margin: 0 0 13.3333333333vw;
  }
}
.p-index-ranking__inner {
  width: min(100000vw, 1000px);
  padding: 0 min(4000vw, 40px);
  margin: 0 auto;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .p-index-ranking__inner {
    width: auto;
    padding: 0 8vw;
  }
}
.p-index-ranking__inner ul {
  display: flex;
  flex-wrap: wrap;
  column-gap: min(1000vw, 10px);
}
@media (max-width: 768px) {
  .p-index-ranking__inner ul {
    display: block;
  }
}
.p-index-ranking__inner ul li {
  width: calc((100% - min(4000vw, 40px)) / 5);
}
@media (max-width: 768px) {
  .p-index-ranking__inner ul li {
    width: auto;
    margin: 0 0 4vw;
  }
}
.p-index-ranking__inner ul li a {
  display: block;
}
@media (max-width: 768px) {
  .p-index-ranking__inner ul li a {
    padding: 0 0 0 8vw;
    display: flex;
    flex-wrap: wrap;
    column-gap: 2.6666666667vw;
    position: relative;
  }
}
.p-index-ranking__inner ul li a .label {
  text-align: center;
  margin: 0 0 min(1000vw, 10px);
}
@media (max-width: 768px) {
  .p-index-ranking__inner ul li a .label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    left: -4vw;
  }
}
.p-index-ranking__inner ul li a .label img {
  width: min(5600vw, 56px);
  height: auto;
}
@media (max-width: 768px) {
  .p-index-ranking__inner ul li a .label img {
    width: 9.3333333333vw;
  }
}
.p-index-ranking__inner ul li a .label .num {
  color: #000;
  font-size: min(1800vw, 18px);
  font-weight: 600;
  line-height: 1.4;
  width: min(3000vw, 30px);
  height: min(3000vw, 30px);
  margin: min(1000vw, 10px) auto 0;
  background-color: #fff;
  border-radius: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .p-index-ranking__inner ul li a .label .num {
    font-size: 3.2vw;
    width: 6.6666666667vw;
    height: 6.6666666667vw;
    margin: 0 0 0 1.3333333333vw;
  }
}
.p-index-ranking__inner ul li a figure {
  margin: 0 0 min(2000vw, 20px);
}
@media (max-width: 768px) {
  .p-index-ranking__inner ul li a figure {
    width: 34.6666666667vw;
    margin: 0;
  }
}
.p-index-ranking__inner ul li a figure img {
  width: 100%;
  height: auto;
}
.p-index-ranking__inner ul li a h4 {
  font-size: min(2000vw, 20px);
  font-weight: 600;
  line-height: 1.4;
  margin: 0 0 min(3000vw, 30px);
}
@media (max-width: 768px) {
  .p-index-ranking__inner ul li a h4 {
    font-size: 4.2666666667vw;
    width: 38.6666666667vw;
    margin: 0 0 8vw;
  }
}
.p-index-ranking__inner ul li a .price {
  color: #DD3035;
  font-size: min(2000vw, 20px);
  font-weight: 600;
  line-height: 1;
}
@media (max-width: 768px) {
  .p-index-ranking__inner ul li a .price {
    font-size: 4.2666666667vw;
    position: absolute;
    bottom: 2.6666666667vw;
    left: 45.3333333333vw;
  }
}

.p-index-visitors {
  margin: 0 0 min(10000vw, 100px);
}
@media (max-width: 768px) {
  .p-index-visitors {
    margin: 0 0 13.3333333333vw;
  }
}
.p-index-visitors__inner {
  width: min(100000vw, 1000px);
  padding: 0 min(4000vw, 40px);
  margin: 0 auto;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .p-index-visitors__inner {
    width: auto;
    padding: 0 5.3333333333vw;
  }
}
.p-index-visitors__inner ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: min(4000vw, 40px);
}
@media (max-width: 768px) {
  .p-index-visitors__inner ul {
    row-gap: 4vw;
    display: block;
  }
}
.p-index-visitors__inner ul li {
  flex-basis: 50%;
}
@media (max-width: 768px) {
  .p-index-visitors__inner ul li {
    flex-basis: 100%;
    margin: 0 0 8vw;
  }
}
.p-index-visitors__inner ul li a {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.p-index-visitors__inner ul li a .desc {
  width: min(23200vw, 232px);
}
@media (max-width: 768px) {
  .p-index-visitors__inner ul li a .desc {
    width: 50%;
  }
}
.p-index-visitors__inner ul li a .desc .heading {
  color: #B79027;
  font-size: min(3000vw, 30px);
  line-height: 1;
  text-align: center;
  margin: 0 0 min(2000vw, 20px);
}
@media (max-width: 768px) {
  .p-index-visitors__inner ul li a .desc .heading {
    font-size: 3.7333333333vw;
    margin: 0 0 2.6666666667vw;
  }
}
.p-index-visitors__inner ul li a .desc h4 {
  color: #000;
  font-size: min(3000vw, 30px);
  font-weight: 600;
  line-height: 1.1;
  text-align: center;
  margin: 0 0 min(1200vw, 12px);
}
@media (max-width: 768px) {
  .p-index-visitors__inner ul li a .desc h4 {
    font-size: 4.8vw;
    margin: 0 0 4vw;
  }
}
.p-index-visitors__inner ul li a .desc .price {
  color: #000;
  font-size: min(2800vw, 28px);
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1.2;
  text-align: center;
  margin: 0 0 min(1200vw, 12px);
}
@media (max-width: 768px) {
  .p-index-visitors__inner ul li a .desc .price {
    font-size: 4.8vw;
    margin: 0 0 4vw;
  }
}
.p-index-visitors__inner ul li a .desc .price span {
  display: block;
  font-size: min(2400vw, 24px);
  font-weight: 400;
}
@media (max-width: 768px) {
  .p-index-visitors__inner ul li a .desc .price span {
    font-size: 3.4666666667vw;
  }
}
.p-index-visitors__inner ul li a .desc .toushitsu {
  color: #FF6600;
  font-size: min(3200vw, 32px);
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1;
  text-align: center;
}
@media (max-width: 768px) {
  .p-index-visitors__inner ul li a .desc .toushitsu {
    font-size: 5.3333333333vw;
  }
}
.p-index-visitors__inner ul li a figure {
  width: min(22800vw, 228px);
}
@media (max-width: 768px) {
  .p-index-visitors__inner ul li a figure {
    width: 50%;
  }
}
.p-index-visitors__inner ul li a figure img {
  width: 100%;
  height: auto;
}

.p-index-voice {
  margin: 0 0 min(10000vw, 100px);
}
@media (max-width: 768px) {
  .p-index-voice {
    margin: 0 0 13.3333333333vw;
  }
}
.p-index-voice__inner {
  width: min(100000vw, 1000px);
  padding: 0 min(4000vw, 40px);
  margin: 0 auto;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .p-index-voice__inner {
    width: auto;
    padding: 0 5.3333333333vw;
  }
}
.p-index-voice__inner dl {
  padding: min(2000vw, 20px);
  margin: 0 0 min(2000vw, 20px);
  background-color: #eee;
  border: min(100vw, 1px) solid #ccc;
}
@media (max-width: 768px) {
  .p-index-voice__inner dl {
    padding: 2.6666666667vw;
    margin: 0 0 4vw;
    border-width: 0.2666666667vw;
  }
}
.p-index-voice__inner dl dt {
  margin: 0 0 min(1000vw, 10px);
  padding: 0 0 min(1000vw, 10px);
  border-bottom: min(200vw, 2px) dashed #d4d4d4;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .p-index-voice__inner dl dt {
    margin: 0 0 1.3333333333vw;
    padding: 5.3333333333vw 0 1.3333333333vw;
    border-width: 0.2666666667vw;
    position: relative;
  }
}
.p-index-voice__inner dl dt .rating {
  font-size: min(1600vw, 16px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .p-index-voice__inner dl dt .rating {
    font-size: 3.2vw;
    display: block;
  }
}
.p-index-voice__inner dl dt .rating img {
  width: auto;
  height: min(1400vw, 14px);
  margin: 0 0 0 min(1000vw, 10px);
}
@media (max-width: 768px) {
  .p-index-voice__inner dl dt .rating img {
    height: 2.6666666667vw;
    margin: 1.3333333333vw 0 0;
    display: block;
  }
}
.p-index-voice__inner dl dt .name {
  font-size: min(1600vw, 16px);
}
@media (max-width: 768px) {
  .p-index-voice__inner dl dt .name {
    font-size: 3.7333333333vw;
    position: absolute;
    top: 1.3333333333vw;
    right: 0;
  }
}
.p-index-voice__inner dl dd {
  padding: min(1000vw, 10px) min(2000vw, 20px);
  background-color: #fff;
}
@media (max-width: 768px) {
  .p-index-voice__inner dl dd {
    padding: 2.6666666667vw;
  }
}
.p-index-voice__inner dl dd p {
  font-size: min(1600vw, 16px);
  line-height: 1.4;
}
@media (max-width: 768px) {
  .p-index-voice__inner dl dd p {
    font-size: 3.7333333333vw;
  }
}

.u-fadein-up {
  opacity: 0;
  transform: translateY(100px);
  transition: all 1.3s ease;
}
@media (min-width: 769px) and (max-width: 1439px) {
  .u-fadein-up {
    transform: translateY(6.944vw);
  }
}
@media (max-width: 768px) {
  .u-fadein-up {
    transform: translateY(13.333vw);
  }
}
.u-fadein-up.is_show {
  opacity: 1;
  transform: translateY(0);
}
.u-fadein-up.two {
  transition-delay: 0.2s;
}
@media (max-width: 768px) {
  .u-fadein-up.two {
    transition-delay: unset;
  }
}
.u-fadein-up.three {
  transition-delay: 0.4s;
}
@media (max-width: 768px) {
  .u-fadein-up.three {
    transition-delay: unset;
  }
}
.u-fadein-up.four {
  transition-delay: 0.5s;
}
@media (max-width: 768px) {
  .u-fadein-up.four {
    transition-delay: unset;
  }
}
.u-fadein-up.five {
  transition-delay: 0.7s;
}
@media (max-width: 768px) {
  .u-fadein-up.five {
    transition-delay: unset;
  }
}

.u-pc-hide {
  display: none;
}
@media (max-width: 768px) {
  .u-pc-hide {
    display: block;
  }
}

.u-sp-hide {
  display: block;
}
@media (max-width: 768px) {
  .u-sp-hide {
    display: none;
  }
}