@use "global" as *;

// ============================================================
//  project/_index-blog.scss
// ============================================================

.p-index-blog{
	margin: 0 0 vw(100);
	@include sp{
		margin: 0 0 svw(50);
	}//sp
	
	&__inner{
		width: vw(1000);
		padding: 0 vw(40);
		margin: 0 auto;
		box-sizing: border-box;
		@include sp{
			width: auto;
			padding: 0 svw(20);
		}//sp
		
		ul{
			li{
				margin: 0 0 vw(20);
				position: relative;
				@include sp{
					margin: 0 0 svw(15);
				}//sp
				
				&::before{
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 vw(11) vw(12) vw(11);
					border-color: transparent transparent #E13035 transparent;
					position: absolute;
					left: calc(0% - vw(8));
					top: calc(0% - vw(2));
					transform: rotate(-45deg);
					z-index: 1;
					@include sp{
						border-width: 0 svw(5.5) svw(6) svw(5.5);
						left: svw(-2);
						top: svw(-1);
					}//sp
				}//::befeore
				
				&::after{
					content: "";
					width: vw(14);
					height: vw(14);
					border-top: vw(3) solid #e13035;
					border-right: vw(3) solid #e13035;
					display: block;
					position: absolute;
					top: 50%;
					right: vw(50);
					transform: translateY(-50%) rotate(45deg);
					@include sp{
						width: svw(8);
						height: svw(8);
						border-width: svw(1);
						display: block;
						position: absolute;
						top: 50%;
						right: svw(20);
					}//sp
				}//::after
				
				a{
					font-size: vw(20);
					font-weight: 600;
					line-height: 1;
					text-align: left;
					padding: vw(20) vw(30);
					border: vw(4) solid #e1dccf;
					display: block;
					@include sp{
						font-size: svw(18);
						line-height: 1.4;
						padding: svw(8) svw(30) svw(8) svw(15);
						border-width: svw(2);
					}//sp
				}//a
			}//li
		}//ul
	}//__inner
}//.p-index-main-visual