@use "global" as *;

// ============================================================
//  foundation/_settings.scss
// ============================================================

/*----------------------------------------
	Common
----------------------------------------*/
/*
html{
	scroll-behavior: smooth;
}//html
*/
body{
	/*
	color: #4A4847;
	font-family: $fontGothic;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	text-size-adjust: 100%;
	word-break: break-word;
	height: 100%;
	background-color: #fafafa;
	overflow-x: hidden;
	
	&.is_fixed{
		overflow: hidden;
	}//is_fixed
	*/
	//min-width: auto;
	min-width: 1024px;
	@include sp{
		min-width: 100%;
		padding: svw(90) 0 0 !important;
	}//sp
}//body
/*
a{
	color: #222;
	text-decoration: none;
	transition: all .6s ease;
}//a

img{
	vertical-align: bottom;
	-webkit-backface-visibility: hidden;
}//img
*/

/* スマホサイドメニュー */

#sideMenu{
	display: none;
	top: svw(60) !important;
	right: auto;
	left: -100vw;
	transition: all .6s ease;
	@include sp{
		display: block;
	}//sp
	
	&.is_active{
		left: 0;
	}//.is_active
}//.sideMenu

/* フッターナビ */
.l-new-footer02{
	@include sp{
		display: none;
	}//sp
}//.l-new-footer02