// ============================================================
//  global/_mixin.scss
// ============================================================

$sp: 480px; //デザインカンプの横幅
$tb: 769px;
$pc: 1440px; //PCコンテンツ幅の最小値
$wide: 1600px; //PCのmax-widthの数値

@mixin sp {
  @media (max-width: $tb - 1){
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tb) and (max-width: $pc - 1){
    @content;
  }
}

@mixin pc {
  @media (min-width: $pc){
   @content;
  }
}

//VW
@function vw($value) {
  $vw: calc($value / 1) * 100 * 1vw;
  $px: $value + px;
  @return min($vw, $px);
}

@function svw($value) {
    $vw: calc($value / 375) * 100 * 1vw;
    @return $vw;
}