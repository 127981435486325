@use "global" as *;

// ============================================================
//  component/_modal-menu.scss
// ============================================================

.c-modal-menu{
	width: 100%;
	height: 100%;
	background: transparent url("../images/common/noise_bg.png") repeat;
	background-size: vw(250);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 95;
	overflow: scroll;
	display: none;
	
	&__inner{
		width: 100%;
		height: 100%;
		position: relative;
		
		&__logo{
			position: absolute;
			top: svw(15);
			left: svw(20);
			
			a{
				display: block;
				
				&:hover{
					opacity: .6;
				}//:hover
				
				img{
					width: auto;
					height: svw(50);
				}//img
			}//a
		}//__logo
		
		&__gnavi{
			width: 100%;
			position: absolute;
			top: 42%;
			transform: translateY(-50%);
			
			ul{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				flex-direction: column;
				row-gap: svw(50);
				
				li{
					a{
						color: #fff;
						font-size: svw(20);
						font-weight: 600;
						letter-spacing: .15em;
						line-height: 1;
					}//a
				}//li
			}//ul
		}//__gnavi
		
		&__contact{
			position: absolute;
			left: 50%;
			bottom: svw(90);
			transform: translateX(-50%);
			
			a{
				width: svw(290);
				display: block;
				
				&:hover{
					opacity: .6;
				}//:hover
				
				img{
					width: 100%;
					height: auto;
				}//img
			}//a
		}//__contact
		
		&__copyright{
			color: #fff;
			font-size: svw(14);
			font-weight: 500;
			letter-spacing: .05em;
			line-height: 1;
			text-align: center;
			width: 100%;
			position: absolute;
			bottom: svw(40);
		}//__copyright
	}//__inner
}//.c-modal-menu