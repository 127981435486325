@use "global" as *;

// ============================================================
//  utility/_hide.scss
// ============================================================

.u-pc-hide{
	display: none;
	@include sp{
		display: block;
	}//sp
}//.u-pc-hide

.u-sp-hide{
	display: block;
	@include sp{
		display: none;
	}//sp
}//.u-sp-hide