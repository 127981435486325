@use "global" as *;

// ============================================================
//  utility/_fade-in.scss
// ============================================================

.u-fadein-up{
	opacity: 0;
	transform: translateY(100px);
	transition: all 1.3s ease;
	@include tablet{
		transform: translateY(6.944vw);
	}//tablet
	@include sp{
		transform: translateY(13.333vw);
	}//sp
	
	&.is_show{
		opacity: 1;
		transform: translateY(0);
	}//.is_show
	
	&.two{
		transition-delay: .2s;
		@include sp{
			transition-delay: unset;
		}//sp
	}//.two
	
	&.three{
		transition-delay: .4s;
		@include sp{
			transition-delay: unset;
		}//sp
	}//.two
	
	&.four{
		transition-delay: .5s;
		@include sp{
			transition-delay: unset;
		}//sp
	}//.two
	
	&.five{
		transition-delay: .7s;
		@include sp{
			transition-delay: unset;
		}//sp
	}//.two
}//.u-fade-in