@use "global" as *;

// ============================================================
//  project/_index-voice.scss
// ============================================================

.p-index-voice{
	margin: 0 0 vw(100);
	@include sp{
		margin: 0 0 svw(50);
	}//sp
	
	&__inner{
		width: vw(1000);
		padding: 0 vw(40);
		margin: 0 auto;
		box-sizing: border-box;
		@include sp{
			width: auto;
			padding: 0 svw(20);
		}//sp
		
		dl{
			padding: vw(20);
			margin: 0 0 vw(20);
			background-color: #eee;
			border: vw(1) solid #ccc;
			@include sp{
				padding: svw(10);
				margin: 0 0 svw(15);
				border-width: svw(1);
			}//sp
			
			dt{
				margin: 0 0 vw(10);
				padding: 0 0 vw(10);
				border-bottom: vw(2) dashed #d4d4d4;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				@include sp{
					margin: 0 0 svw(5);
					padding: svw(20) 0 svw(5);
					border-width: svw(1);
					position: relative;
				}//sp
				
				.rating{
					font-size: vw(16);
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					@include sp{
						font-size: svw(12);
						display: block;
					}//sp
					
					img{
						width: auto;
						height: vw(14);
						margin: 0 0 0 vw(10);
						@include sp{
							height: svw(10);
							margin: svw(5) 0 0;
							display: block;
						}//sp
					}//img
				}//.rating
				
				.name{
					font-size: vw(16);
					@include sp{
						font-size: svw(14);
						position: absolute;
						top: svw(5);
						right: 0;
					}//sp
				}//.name
			}//dt
			
			dd{
				padding: vw(10) vw(20);
				background-color: #fff;
				@include sp{
					padding: svw(10);
				}//sp
				
				p{
					font-size: vw(16);
					line-height: 1.4;
					@include sp{
						font-size: svw(14);
					}//sp
				}//p
			}//dd
		}//dl
	}//__inner
}//.p-index-main-visual