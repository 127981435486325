@use "global" as *;

// ============================================================
//  component/_section-title.scss
// ============================================================

.c-section-title{
	margin: 0 0 vw(50);
	@include sp{
		margin: 0 0 svw(25);
	}//sp
	
	.en{
		text-align: center;
		margin: 0 0 vw(20);
		@include sp{
			margin: 0 0 svw(15);
		}//sp
		
		span{
			color: #BA9327;
			font-size: vw(26);
			font-weight: 100;
			letter-spacing: 0;
			line-height: 1;
			text-align: center;
			border-bottom: 2px solid #BA9327;
			@include sp{
				font-size: svw(13);
				border-width: svw(1);
			}//sp
		}//span
	}//.en
	
	h3{
		color: #535353;
		font-size: vw(46);
		font-weight: 600;
		text-align: center;
		@include sp{
			font-size: svw(26);
		}//sp
	}//h3
}//.c-section-title