@use "global" as *;

// ============================================================
//  project/_index-main-visual.scss
// ============================================================

.p-index-main-visual{
	margin: 0 0 vw(60);
	overflow: hidden;
	@include sp{
		margin: 0 0 svw(50);
	}//sp
	
	&:hover{
		.slick-arrow{
			opacity: 1;
			
			&.prev{
				left: calc(0% - vw(40));
			}//.prev
			
			&.next{
				right: calc(0% - vw(40));
			}//.next
		}//.slick-arrow
	}//:hover
	
	&__inner{
		width: vw(1000);
		margin: 0 auto;
		@include sp{
			width: auto;
		}//sp
		
		.slide{
			p{
				font-size: vw(18);
				line-height: 1;
				text-align: center;
				margin: vw(10) 0 0;
				@include sp{
					font-size: svw(16);
					margin: svw(15) 0 0;
				}//sp
			}//p
		}//.slide
		
		.slick-arrow{
			line-height: 0;
			position: absolute;
			top: vw(250);
			z-index: 1;
			cursor: pointer;
			opacity: 0;
			transition: all .3s ease;
			@include sp{
				display: none !important;
			}//sp
			
			&:hover{
				opacity: .3;
			}//:hover
			
			&.prev{
				left: calc(0% - vw(20));
			}//.prev
			
			&.next{
				right: calc(0% - vw(20));
			}//.next
			
			svg{
				width: vw(16);
				height: auto;
			}//svg
		}//.slick-arrow
		
		.slick-dots{
			margin: vw(30) 0 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			column-gap: vw(15);
			@include sp{
				margin: svw(30) 0 0;
				column-gap: svw(8);
			}//sp
			
			li{
				line-height: 0;
				position: relative;
				
				button{
					color: transparent;
					font-size: 0;
					line-height: 0;
					width: vw(10);
					height: vw(10);
					padding: 0;
					background-color: #cbcbcb;
					border-radius: 100%;
					transition: all .6s ease;
					appearance: none;
					border: none;
					outline: none;
					cursor: pointer;
					@include sp{
						width: svw(10);
						height: svw(10);
					}//sp
				}//button
				
				&.slick-active{
					button{
						background-color: #535353;
					}//button
				}//.slick-active
			}//li
		}//.slick-dots
	}//__inner
}//.p-index-main-visual