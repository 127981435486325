@use "global" as *;

// ============================================================
//  project/_index-visitors.scss
// ============================================================

.p-index-visitors{
	margin: 0 0 vw(100);
	@include sp{
		margin: 0 0 svw(50);
	}//sp
	
	&__inner{
		width: vw(1000);
		padding: 0 vw(40);
		margin: 0 auto;
		box-sizing: border-box;
		@include sp{
			width: auto;
			padding: 0 svw(20);
		}//sp
		
		ul{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			row-gap: vw(40);
			@include sp{
				row-gap: svw(15);
				display: block;
			}//sp
			
			li{
				flex-basis: 50%;
				@include sp{
					flex-basis: 100%;
					margin: 0 0 svw(30);
				}//sp
				a{
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					
					.desc{
						width: vw(232);
						@include sp{
							width: 50%;
						}//sp
						
						.heading{
							color: #B79027;
							font-size: vw(30);
							line-height: 1;
							text-align: center;
							margin: 0 0 vw(20);
							@include sp{
								font-size: svw(14);
								margin: 0 0 svw(10);
							}//sp
						}//.heading
						
						h4{
							color: #000;
							font-size: vw(30);
							font-weight: 600;
							line-height: 1.1;
							text-align: center;
							margin: 0 0 vw(12);
							@include sp{
								font-size: svw(18);
								margin: 0 0 svw(15);
							}//sp
						}//h4
						
						.price{
							color: #000;
							font-size: vw(28);
							font-weight: 600;
							letter-spacing: .05em;
							line-height: 1.2;
							text-align: center;
							margin: 0 0 vw(12);
							@include sp{
								font-size: svw(18);
								margin: 0 0 svw(15);
							}//sp
							
							span{
								display: block;
								font-size: vw(24);
								font-weight: 400;
								@include sp{
									font-size: svw(13);
								}//sp
							}//span
						}//.price
						
						.toushitsu{
							color: #FF6600;
							font-size: vw(32);
							font-weight: 400;
							letter-spacing: .05em;
							line-height: 1;
							text-align: center;
							@include sp{
								font-size: svw(20);
							}//sp
						}//.toushitsu
					}//.desc
					
					figure{
						width: vw(228);
						@include sp{
							width: 50%;
						}//sp
						
						img{
							width: 100%;
							height: auto;
						}//img
					}//figure
				}//a
			}//li
		}//ul
	}//__inner
}//.p-index-main-visual