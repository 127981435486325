@use "global" as *;

// ============================================================
//  project/_index-important.scss
// ============================================================



.event_bnr{
	max-width: vw(1000);
	margin: 0 auto;
	&.pc{
		margin-top: vw(20);
	}
	&.sp{
		margin-bottom: vw(10);
	}	
}



.p-index-important{
	padding: vw(20) 0;
	@include sp{
		padding: 0;	
	}//sp
	
	&__inner{
		width: vw(1000);
		margin: 0 auto;
		box-sizing: border-box;
		@include sp{
			width: auto;
		}//sp
		
		dl{
			dt{
				color: #fff;
				font-size: vw(24);
				font-weight: 600;
				line-height: 1;
				height: vw(40);
				background-color: #e13035;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				position: relative;
				@include sp{
					font-size: svw(15);
					font-weight: 600;
					line-height: 1;
					height: svw(40);
				}//sp
				
				.icon{
					width: vw(24);
					height: vw(24);
					background-color: #fff;
					border-radius: vw(5);
					position: absolute;
					top: 50%;
					right: vw(30);
					transform: translateY(-50%);
					@include sp{
						width: svw(20);
						height: svw(20);
						border-radius: svw(5);
						right: svw(15);
					}//sp
					
					&:before,
					&:after{
						content: "";
						width: vw(16);
						height: vw(2);
						background-color: #e13035;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						@include sp{
							width: svw(10);
							height: svw(2);
						}//sp
					}//
					
					&:after{
						width: vw(2);
						height: vw(16);
						@include sp{
							width: svw(2);
							height: svw(10);
						}//sp
					}//:after
				}//.icon
				
				&.is_active{
					.icon{
						&:after{
							display: none;
						}//:after
					}//.icon
				}//.is_active
			}//dt
			
			dd{
				display: none;
				
				ul{
					text-align: left;
					padding: vw(20) vw(40) 0;
					@include sp{
						padding: svw(10) svw(10) svw(15);
					}//sp
					
					li{
						margin: 0 0 vw(8);
						@include sp{
							margin: 0 0 svw(5);
						}//sp
						
						a{
							font-size: vw(18);
							line-height: 1.4;
							text-decoration: underline;
							@include sp{
								font-size: svw(18);
							}//sp
							
							&:hover{
								text-decoration: none;
							}//:hover
						}//a
					}//li
				}//ul
			}//dd
		}//dl
	}//__inner
}//.p-index-information