@use "global" as *;

// ============================================================
//  project/_index-rainking.scss
// ============================================================

.p-index-ranking{
	padding: vw(50) 0;
	margin: 0 0 vw(100);
	@include sp{
		padding: svw(25) 0;
		margin: 0 0 svw(50);
	}//sp
	
	&__inner{
		width: vw(1000);
		padding: 0 vw(40);
		margin: 0 auto;
		box-sizing: border-box;
		@include sp{
			width: auto;
			padding: 0 svw(30);
		}//sp
		
		ul{
			display: flex;
			flex-wrap: wrap;
			column-gap: vw(10);
			@include sp{
				display: block;
			}//sp
			
			li{
				width: calc((100% - vw(40))/5);
				@include sp{
					width: auto;
					margin: 0 0 svw(15);
				}//sp
				
				a{
					display: block;
					@include sp{
						padding: 0 0 0 svw(30);
						display: flex;
						flex-wrap: wrap;
						column-gap: svw(10);
						position: relative;
					}//sp
					
					.label{
						text-align: center;
						margin: 0 0 vw(10);
						@include sp{
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
							left: svw(-15);
						}//sp
						
						img{
							width: vw(56);
							height: auto;
							@include sp{
								width: svw(35);
							}//sp
						}//img
						
						.num{
							color: #000;
							font-size: vw(18);
							font-weight: 600;
							line-height: 1.4;
							width: vw(30);
							height: vw(30);
							margin: vw(10) auto 0;
							background-color: #fff;
							border-radius: 100%;
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							justify-content: center;
							@include sp{
								font-size: svw(12);
								width: svw(25);
								height: svw(25);
								margin: 0 0 0 svw(5);
							}//sp
						}//.num
					}//.label
					
					figure{
						margin: 0 0 vw(20);
						@include sp{
							width: svw(130);
							margin: 0;
						}//sp
						
						img{
							width: 100%;
							height: auto;
						}//img
					}//figure
					
					h4{
						font-size: vw(20);
						font-weight: 600;
						line-height: 1.4;
						margin: 0 0 vw(30);
						@include sp{
							font-size: svw(16);
							width: svw(145);
							margin: 0 0 svw(30);
						}//sp
					}//h4
					
					.price{
						color: #DD3035;
						font-size: vw(20);
						font-weight: 600;
						line-height: 1;
						@include sp{
							font-size: svw(16);
							position: absolute;
							bottom: svw(10);
							left: svw(170);
						}//sp
					}//.price
				}//a
			}//li
		}//ul
	}//__inner
}//.p-index-main-visual